import { lazy } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

import { DashboardLayout } from "./layouts";
import RouterGuard from "./routerGuard";

const AnnouncementsPage = lazy(
  async () => await import("./pages/announcements")
);
const DetailsGroup = lazy(async () => await import("./pages/detailsGroup"));
const DetailsItem = lazy(async () => await import("./pages/detailsItem"));
const GroupsPage = lazy(async () => await import("./pages/groups"));
const SalariesPage = lazy(async () => await import("./pages/salaries"));
const VacationsPage = lazy(async () => await import("./pages/vacations"));
const ItemsPage = lazy(async () => await import("./pages/items"));
const LoginPage = lazy(async () => await import("./pages/login"));
const SearchPage = lazy(async () => await import("./pages/search"));
const SettingsPage = lazy(async () => await import("./pages/settings"));
const CheckLoginTokenPage = lazy(
  async () => await import("./pages/checkLoginToken")
);

export const routeNames = {
  login: "/login",
  checkLoginToken: "/check-login-token/:token",
  dashboard: "/",
  detailsItem: "/details-item/:id",
  announcements: "/announcements",
  groups: "/groups",
  salaries: "/salaries",
  vacations: "/vacations",
  detailsGroup: "/groups/:id",
  search: "search/:searchValue",
  announcementSetting: "/settings/announcement",
  setting: "/settings/:pageName",
  itemSetting: "/settings/item",
  groupSetting: "/settings/group",
  userSetting: "/settings/user",
};

const router = createBrowserRouter([
  {
    path: routeNames.login,
    element: (
      <RouterGuard status="unauthorize">
        <LoginPage />
      </RouterGuard>
    ),
  },
  {
    path: routeNames.checkLoginToken,
    element: (
      <RouterGuard status="unauthorize">
        <CheckLoginTokenPage />
      </RouterGuard>
    ),
  },
  {
    path: routeNames.dashboard,
    element: (
      <RouterGuard status="authorize">
        <DashboardLayout />
      </RouterGuard>
    ),
    children: [
      {
        path: routeNames.dashboard,
        element: <ItemsPage />,
      },
      {
        path: routeNames.announcements,
        element: <AnnouncementsPage />,
      },
      {
        path: routeNames.detailsItem,
        element: <DetailsItem />,
      },
      {
        path: routeNames.groups,
        element: <GroupsPage />,
      },
      {
        path: routeNames.salaries,
        element: <SalariesPage />,
      },
      {
        path: routeNames.vacations,
        element: <VacationsPage />,
      },
      {
        path: routeNames.detailsGroup,
        element: <DetailsGroup />,
      },
      {
        path: routeNames.setting,
        element: (
          <RouterGuard status="authorize" userType="admin">
            <SettingsPage />
          </RouterGuard>
        ),
      },
      {
        path: routeNames.search,
        element: <SearchPage />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

export default router;
